import ErrorHandler from '@/services/ErrorHandler';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  /**
   * Startup errors
   * @see https://nuxt.com/docs/getting-started/error-handling#startup-errors
   */
  nuxtApp.hook('app:error', (err) => {
    /* @todo: Improvement: Send error to Sentry (dev, prod) */
    /* @todo: Improvement: Show error modal/page (dev, prod?) */
    nuxtApp.$log.error('[storefront:error:app]', err);
  });

  /**
   * Vue lifecycle errors - including unhandled and handled errors
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    /* @todo: Improvement: Send error to Sentry (dev, prod) */
    /* @todo: Improvement: Show error modal/page (dev, prod?) */
    nuxtApp.$log.error('[storefront:error:vue]', { error, instance, info });
  };

  /**
   * Vue lifecycle errors - includes handled errors only
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  // nuxtApp.hook('vue:error', (error, instance, info) => {
  //   // logger.error('[storefront:error:vue:unhandled]', { error, instance, info });
  // });

  nuxtApp.$router.onError((error, to) => {
    nuxtApp.$log.error('[storefront:error:router]', error);
    // Vite randomly causes failed imports for router pages or other async modules.
    // So far this has only been observed in dev builds, which might be explained by congestion due to the
    // ~1400 requests we send, process, return and finally parse in the browser, all in about 2-3 seconds.
    // @see https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566 reload workaround from here
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      // One of the few exceptions where we actually want to use window.location and bypass all nuxt code
      window.location = to?.fullPath;
    }
  });
  if (import.meta.client) {
    window.addEventListener('vite:preloadError', (event) => {
      window.location.reload(); // for example, refresh the page
    });
  }

  return {
    provide: {
      errorHandler: new ErrorHandler(),
    },
  };
});
